import * as React from "react";
import PageWrapper from "../PageWrapper";
import styled from "@emotion/styled";
import {
  RightOutlined,
} from "@ant-design/icons"
import {ReactNode} from "react";

const Container = styled.div`
  margin: 0 auto;
  width: ${(props: any) => props.width}px;
  display: flex;
  gap: 24px;
`

const Menu = styled.div`
  padding-top: 24px;
  width: 196px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const MenuItem = styled.a`
  padding: 0 24px;
  width: 148px;
  height: 46px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC,sans-serif;
  font-weight: 500;
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  & .anticon {
    color: rgba(0, 0, 0, 0.8);
  }
  &.selected, &:hover {
    color: rgb(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`

export type RecommendLayoutProps = {
  width?: number,
  selected?: 'recommend' | 'manager' | 'design' | 'develop'
  menu?: any;
  children?: ReactNode,
}


const RecommendLayout =  (props: RecommendLayoutProps) => {
  const {
    width = 1000,
    selected = '',
    menu = [],
    children,
  } = props

  return (<>
    <PageWrapper authorized>
      <Container {...{width}}>
        <Menu>
          {/*<MenuItem href='/personal/workbench' className={selected == "workbench" ? 'selected' : ''}><AppstoreOutlined/> 工作台</MenuItem>*/}
          {/*<MenuItem href='/personal/enterprise' className={selected == "enterprise" ? 'selected' : ''}><FileDoneOutlined/>企业信息</MenuItem>*/}
          {/*<MenuItem href='/personal/resume' className={selected == "resume" ? 'selected' : ''}><UserOutlined/>个人履历</MenuItem>*/}
          {/*<MenuItem href='#' className={selected == "income" ? 'selected' : ''}><WalletOutlined/>我的收益</MenuItem>*/}
          {/*<MenuItem href='#' className={selected == "project" ? 'selected' : ''}><ControlOutlined />项目管理</MenuItem>*/}
          {/*<MenuItem href='/personal/setting' className={selected == "setting" ? 'selected' : ''}><SettingOutlined />系统设置</MenuItem>*/}
          {
          //   [
          //   {
          //     href: '/recommend',
          //     key: 'recommend',
          //     text: '推荐资源'
          //   },
          //   {
          //     href: '/recommend/manager',
          //     key: 'manager',
          //     text: '项目管理',
          //   },
          //   {
          //     href: '/recommend/design',
          //     key: 'design',
          //     text: '设计工具',
          //   },
          //   {
          //     href: '/recommend/develop',
          //     key: 'develop',
          //     text: '开发资源',
          //   },
          // ]
            menu.map(item => (
            <MenuItem href={item.href} key={item.key} className={selected == item.key ? 'selected' : ''}>
              {item.text} {selected == item.key && (<RightOutlined style={{fontSize: 10}} />)}
            </MenuItem>
          ))}
        </Menu>
        {children}
      </Container>
    </PageWrapper>
  </>)
}

export default RecommendLayout
