import * as React from "react";
import RecommendLayout from "../../components/pages/recommend/RecommendLayout";
import RecommendBody from "../../components/pages/recommend/RecommendBody";
import Seo from "../../components/seo";
import {useRequest} from "ahooks";
import jsonRequest from "../../utils/request/jsonRequest";
import {useEffect, useState} from "react";
import {navigate} from "gatsby";

// const recommends = [
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/modao.png").default,
//     name: '墨刀',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
//   {
//     image: require("../../assets/pages/recommends/github.png").default,
//     name: 'GitHub',
//     desc: '开源及私有软件托管平台',
//     link: 'https://www.github.com',
//   },
// ]

const Recommend = ({location}) => {
  const [selectedText, setSelectedText] = useState('')
  const {data: menu} = useRequest(() => {
    return jsonRequest("/recommend/group").then(rsp => {
      return rsp.data?.data.map(group => ({
        href: `/recommend/#${group.id}`,
        key: `#${group.id}`,
        text: group.name,
      }))
    })
  })
  const {data: recommends, run: loadRecommend} = useRequest((id: any) => {
    return jsonRequest(`/recommend?groupId=${id}`).then(rsp => {
      return rsp.data?.data
    })
  }, {
    manual: false,
  })
  useEffect(() => {
    let found = false
    console.log(menu)
    if ((menu?.length ?? 0) == 0) {
      return
    }
    menu?.forEach(item => {
      if (item.key == location.hash) {
        found = true
        setSelectedText(item.text)
      }
    });
    if (!found) {
      navigate(`${location.pathname}${location.search}${menu[0].key}`)
      return;
    } else {
      const groupId = location.hash.replace('#', '')
      loadRecommend(groupId)
    }
  }, [menu, location])
  return (<>
    <RecommendLayout selected={location.hash} {...{menu}}>
      <RecommendBody
        title={selectedText}
        {...{recommends}}
      />
    </RecommendLayout>
  </>)
}

export default Recommend;

export const Head = () => <Seo title='GitWork-合作伙伴' />
